<template>
    <user-info-layout hideBackButton>
        <template #heading-text>What will you be using Voiceform for?</template>
        <v-container class="pa-0">
            <div class="d-flex flex-wrap">
                <v-card
                    v-for="useOption in useOptions"
                    :key="useOption.value"
                    :height="$vuetify.breakpoint.xs?92: 185"
                    :width="$vuetify.breakpoint.xs?84:164"
                    :elevation="0"
                    :color="useOption.value === useType ? 'primary' : 'primary lighten-5'"
                    @click="handleClick(useOption.value)"
                    class="mr-4 mr-sm-16 mb-4 card"
                    :style="`border: 1px solid ${useOption.value === useType?'var(--v-primary-base)':'var(--v-primary-darken1)'} !important;`"
                >
                    <v-icon
                        :size="$vuetify.breakpoint.xs?40:80"
                        class="d-flex mt-1 mt-sm-8"
                        :color="useOption.value === useType ? 'white' : 'primary darken-1'"
                    >
                    {{ useOption.icon }}
                    </v-icon>
                    <div
                        class="text-body-1 text-sm-h6 mt-2 mt-sm-4 text-center text-spacing"
                        :style="{ color: useOption.value === useType ? 'white' : 'var(--v-primary-darken1)'}"
                    >
                        {{useOption.label}}
                    </div>
                </v-card>
            </div>
        </v-container>
    </user-info-layout>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import UserInfoLayout from '../../layouts/UserInfoLayout.vue';

export default {
    name: "UseType",
    components: {
        UserInfoLayout,
    },
    computed: {
        ...mapGetters({
            useType: 'userInfoForm/useType',
        }),
    },
    data() {
        return {
            useOptions: [
                {
                    label: 'Work',
                    icon: 'mdi-briefcase-outline',
                    value: 'work', 
                },
                {
                    label: 'School',
                    icon: 'mdi-school-outline',
                    value: 'school', 
                },
                {
                    label: 'Personal',
                    icon: 'mdi-account-outline',
                    value: 'personal', 
                },
            ],
        };
    },
    methods: {
        ...mapMutations({
            setUseType: 'userInfoForm/setUseType',
        }),
        handleClick(val) {
            this.setUseType(val);
            this.$router.push({ name: 'user-info.activity' });
        }
    },
};
</script>

<style scoped lang="scss">
    .card {
        border: 2px solid var(--v-primary-darken1) !important;
        border-radius: 4px;
    }
    .text-spacing {
        letter-spacing: 0.15px;
    }
</style>
